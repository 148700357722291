/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import favicon from "../images/favicon.ico"
import Helmet from "react-helmet"
import { Link } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} />
        <script src="https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js"></script>
      </Helmet>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main className="w-full lg:w-4/6 m-auto">{children}</main>
      <footer className="border-2 border-gray-100">
        <div className="py-5 flex justify-between lg:w-4/6 px-10 m-auto">
          <div className="text-sm ml-5">Copyright 2021 © T-Express</div>
          <div className="text-sm ml-5 hover:underline mr-5">
            <Link to="/tos">Terms of Service</Link>
          </div>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
