import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Header = ({ siteTitle }) => {
  const toggleNavbarDropdown = () => {
    const menu = document.getElementById("navbar-dropdown")
    menu.classList.toggle("hidden")
  }

  return (
    <nav class="px-2 bg-white border-gray-200 dark:bg-gray-900 dark:border-gray-700 bg-texpressblue">
      <div class="container flex flex-wrap items-center justify-between mx-auto">
        <Link to="/" className="py-1 lg:p-0">
          <StaticImage src="../images/Main-logo.png" alt="Logo"></StaticImage>
        </Link>
        <button
          data-collapse-toggle="navbar-dropdown"
          type="button"
          class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-dropdown"
          aria-expanded="false"
          onClick={toggleNavbarDropdown}
        >
          <span class="sr-only">Open main menu</span>
          <svg
            class="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
        <div class="hidden w-full md:block md:w-auto" id="navbar-dropdown">
          <ul class="flex flex-col bg-red p-4 mt-4 border border-gray-100 rounded-lg md:flex-row md:space-x-8 md:mt-5 md:text-sm md:font-medium md:border-0 dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            <li className="my-0">
              <Link
                to="/"
                className="block py-2 pl-3 pr-4 text-white rounded md:hover:bg-gray-100 hover:bg-gray-700 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
              >
                TRANG CHỦ
              </Link>
            </li>
            <li className="my-0">
              <Link
                to="/contact"
                className="block py-2 pl-3 pr-4 text-white rounded md:hover:bg-gray-100 hover:bg-gray-700 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
              >
                LIÊN HỆ
              </Link>
            </li>
            <li className="my-0">
              <Link
                to="https://portal.texpress.net/"
                className="block py-2 pl-3 pr-4 text-white rounded md:hover:bg-gray-100 hover:bg-gray-700 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
              >
                PORTAL
              </Link>
            </li>
            <li className="my-0 lg:border md:border-white">
              <Link
                to="https://portal.texpress.net/tracking"
                className="md:px-5 block pl-3 pr-4 text-white rounded md:hover:bg-gray-100 hover:bg-gray-700 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
              >
                CHECK SHIPMENT
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
